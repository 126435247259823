import { Button, Icon, useMobile } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";
import curacaoGamingLogo from "assets/curacao-gaming-logo.png";
import { clsx } from "clsx";
import { Logo } from "shared/ui";

function FooterMainSection() {
  const { $t } = useTranslate();
  return (
    <div className="flex-center flex-col gap-3 lg:flex-row lg:gap-5">
      <div className="flex gap-2 lg:contents">
        <img src={curacaoGamingLogo} alt="Curacao Gaming" className="h-7 lg:h-11" />
        <div className="flex items-center">
          <div className="mr-1.5 h-5 w-6 flex-center rounded-rounded bg-bright-gray font-extrabold text-10 text-white lg:h-8 lg:w-11 lg:text-18 dark:bg-white dark:text-outer-space">
            {$t({ defaultMessage: "18+" })}
          </div>
          <div className="font-bold text-10/none uppercase italic lg:text-14/none">
            <span>{$t({ defaultMessage: "game" })}</span>
            <br />
            <span>{$t({ defaultMessage: "responsibly" })}</span>
          </div>
        </div>
      </div>
      <p className="mobile-only:-order-1 mobile-only:text-center text-12 lg:text-16">
        {$t(
          {
            defaultMessage:
              "Gambling can be addictive. Play responsibly. {themeName} only accept customers over 18 years of age.",
          },
          {
            themeName: "RICHBET",
          },
        )}
      </p>
    </div>
  );
}

export function Footer({ className }: { className?: string }) {
  const { $t } = useTranslate();
  const isMobile = useMobile();

  return (
    <footer className={clsx(className, "relative")}>
      <div className="mx-auto p-3 lg:max-w-screen-3xl lg:p-5">
        <div className="flex flex-col gap-2 lg:contents">
          <div className="flex-c-sb gap-3 lg:gap-5">
            <Link className="shrink-0" to="/">
              <Logo className="h-9 lg:h-11" />
            </Link>

            {!isMobile && <FooterMainSection />}

            <div
              className="flex-center cursor-pointer gap-2.5 whitespace-nowrap font-medium text-16 text-keppel dark:text-java"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <span>{$t({ defaultMessage: "Back to top" })}</span>
              <Button variant="secondary" fit>
                <Icon className="rotate-90 text-8 lg:text-10" name="arrowLeft" />
              </Button>
            </div>
          </div>

          {isMobile && <FooterMainSection />}
        </div>
      </div>
    </footer>
  );
}
