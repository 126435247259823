import { GameLists } from "@lobby/core/src/components";
import { FortuneWheel } from "@lobby/core/src/entities";
import { useMobile } from "@lobby/core/src/shared";
import { FortuneWheelButton } from "features/fortune-wheel-button";
import { GameSearch } from "features/game-search";
import { TakeBonusButton } from "features/take-bonus-button";
import { FloatGroup } from "shared/ui";
import { BannerPanelDesktop, BannerPanelMobile } from "widget/banner-panel";
import { CategoryList } from "widget/category-list";
import { Footer } from "widget/footer";
import { Header } from "widget/header";
import { JackpotTickerSlots } from "widget/jackpot-ticker-slots";
import { LogInModal } from "widget/log-in-modal";
import { PasswordForgotModal } from "widget/password-forgot-modal";
import { ProviderList, ProvidersModal } from "widget/provider-list";
import { RegisterModal } from "widget/register-modal";

export function HomePage() {
  const isMobile = useMobile();
  const isDesktop = !isMobile;
  const isFortuneWheelAvailable = FortuneWheel.useIsWheelAvailable();

  return (
    <>
      <Header />
      <main className="space-y-3 p-2.5 lg:mx-auto lg:max-w-screen-3xl lg:space-y-5 lg:p-5">
        <>
          <LogInModal />
          <RegisterModal />
          <PasswordForgotModal />
          <ProvidersModal />
        </>
        {isDesktop ? <BannerPanelDesktop /> : <BannerPanelMobile />}
        <JackpotTickerSlots />

        {isDesktop && (
          <div
            className="grid grid-cols-[18.5rem_minmax(0,auto)] items-start gap-5"
            id="navigate-category-anchor"
          >
            <CategoryList />
            <div className="grow space-y-5">
              <ProviderList />
              <GameLists />
            </div>
          </div>
        )}
        {isMobile && (
          <>
            <section>
              <GameSearch className="[&_input]:dark:bg-infinity [&_input]:bg-alto" />
            </section>

            {isFortuneWheelAvailable && <FortuneWheelButton.Mobile />}

            <ProviderList />
            <CategoryList />
            <GameLists id="navigate-category-anchor" />
          </>
        )}
      </main>
      <Footer />

      <FloatGroup>
        <TakeBonusButton />
      </FloatGroup>
    </>
  );
}
